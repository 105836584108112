import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { getPageComponents } from "../../api/getData";
import {
  BANNER_WITH_TITLE_IMAGE,
  CONTENT_COMPONENT_WITH_IMAGE_TITLE,
  TWO_COLUMN_COMPONENT_YEAR_TITLE,
  CONTENT_COMPONENT_WITH_TITLE,
  CONTENT_COMPONENT_FOUR_CARD,
  THREE_CARD_COMPONENT_WITH_TITLE,
  THREE_CARD_COMPONENT_WITH_KICKER,
  COMPONENT_HTML_EDITOR,
  COMPONENT_SPACE,
  COMPONENT_C15_H1,
  COMPONENT_C17_VIDEO,
  CONTAINER_VIDEO_CARD,
  COMPONENT_C18_POINT_LISTS_COMPONENT,
  COMPONENT_C19_TITLE_LISTS_COMPONENT,
  COMPONENT_C20_FAQ_COMPONENT
} from "../../constants/componentTypes";
import Banner from "../../components/Banner/Banner";
import BannerWithTitleDescButton from "../../components/BannerWithTitleDescButton/BannerWithTitleDescButton";
import TitleDescription from "../../components/TitleDescription/TitleDescription";
import CardSliderWithTitle from "../../components/CardSliderWithTitle/CardSliderWithTitle";
import ZigzagHexaCards from "../../components/ZigzagHexaCards/ZigzagHexaCards";
import ImageTextLeftRight from "../../components/ImageTextLeftRight/ImageTextLeftRight";
import ZigzagTriCard from "../../components/ZigzagTriCard/ZigzagTriCard";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import HtmlEditor from "../../components/HtmlEditor/HtmlEditor";
import Loader from "../../components/Loader/Loader";
import Spacer from "../../components/Spacer/Spacer";
import C15_H1 from "../../components/C15_H1";
import C16_VIDEO_CARD from "../../components/Video/C16_Video_Card";
import C17_VIDEO from "../../components/Video/C17_Video";
import C18_POINTLISTS from "../../components/C18_PointLists/C18_PointLists";
import C19_TITLELISTS from "../../components/C19_TitleLists/C19_TitleLists";
import C20_FAQ from "../../components/C20_Faq/C20_FaqLists";
import NotFound from "../../components/NotFound/NotFound";

const Page = ({ locator, pageID, resourceVersionId, currentCountryCode }) => {
  const [loading, setLoading] = useState(true);
  const [pageData, setPageData] = useState(null);
  const [pageAttributes, setPageAttributes] = useState(null);
  const [pageBreadcrumb, setPageBreadcrumb] = useState(false);
  const [pageBreadcrumbData, setPageBreadcrumbData] = useState(null);
  const [hasScrolled, setHasScrolled] = useState(false);
  const [notFound, setNotFound] = useState(false);

  var firstIndex = 0;
  var lastIndex = 0;

  useEffect(() => {
    setPageData(null);
    getPageComponents(
      pageID,
      setPageData,
      setPageAttributes,
      setPageBreadcrumb,
      setPageBreadcrumbData,
      setLoading,
      resourceVersionId,
      currentCountryCode,
      setNotFound
    );
  }, [locator, pageID, resourceVersionId, currentCountryCode]);
  if (pageData) {
    firstIndex = pageData.data?.findIndex((item) =>
      item.type.match(TWO_COLUMN_COMPONENT_YEAR_TITLE)
    );
    lastIndex =
      pageData.data?.filter((item) =>
        item.type.match(TWO_COLUMN_COMPONENT_YEAR_TITLE)
      ).length - 1;
  }

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerWidth <= 767 && !hasScrolled) {
        setHasScrolled(true);
      }
    };
    if (window.innerWidth > 767) {
      setHasScrolled(true);
    }
    window.addEventListener('scroll', handleScroll);

    const handleTouchMove = (e) => {

      if (window.innerWidth <= 767 && !hasScrolled) {
        const currentY = e.touches[0].clientY;
        if (currentY > 0) {
          setHasScrolled(true);
        }
      }
    };

    window.addEventListener('touchmove', handleTouchMove);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('touchmove', handleTouchMove);
    };
  }, [hasScrolled]);
  return (
    <>
      {notFound ? (
        <NotFound />
      ) :
        (loading ? (
          <Loader />
        ) : (
          <>
            {pageAttributes &&
              <Helmet>
                <meta charSet="utf-8" />
                <title>
                  {pageAttributes.field_page_meta_tags?.title ||
                    pageAttributes.title}{" "}
                  | Packimpex
                </title>
                {pageAttributes?.field_robots ? <meta name="robots" content={pageAttributes?.field_robots}></meta> : null}
                <meta
                  name="title"
                  content={
                    pageAttributes?.field_meta_title ||
                    pageAttributes.title
                  }
                ></meta>
                <meta
                  name="description"
                  content={pageAttributes.field_page_description}
                />
                <meta property="og:type" content="website" />
                <meta
                  property="og:title"
                  content={
                    pageAttributes?.field_meta_title ||
                    pageAttributes.title
                  }
                />
                <meta
                  property="og:description"
                  content={pageAttributes.field_page_description}
                />
                <meta name="keywords" content={pageAttributes?.field_keywords}></meta>
              </Helmet>
            }
            {pageData.data?.length > 0 ? (
              <>
                {pageData.data.map((data) => {
                  return (
                    <>
                      {data.type.match(BANNER_WITH_TITLE_IMAGE) && (
                        <Banner componentID={data.id} resourceVersionId={data.meta.target_revision_id} currentCountryCode={currentCountryCode} />
                      )}
                    </>
                  );
                })}
                {pageBreadcrumb ? (
                  <div className="container pt-40">
                    <nav aria-label="breadcrumb">
                      <ol class="breadcrumb">
                        <>
                          {pageBreadcrumbData.map((data) => {
                            return <Breadcrumb id={data.id} resourceVersionId={data.meta.target_revision_id} currentCountryCode={currentCountryCode} />;
                          })}
                        </>
                      </ol>

                    </nav>
                  </div>
                ) : null}
                {pageData.data.map((data, index) => {
                  if (index < 2 || hasScrolled) {
                    return (
                      <>
                        {(data.type.match(CONTENT_COMPONENT_WITH_IMAGE_TITLE) && (
                          <BannerWithTitleDescButton
                            componentID={data.id}
                            firstComponent={index === 0}
                            resourceVersionId={data.meta.target_revision_id}
                            currentCountryCode={currentCountryCode}
                          />
                        )) ||
                          (data.type.match(TWO_COLUMN_COMPONENT_YEAR_TITLE) && (
                            <ImageTextLeftRight
                              componentID={data.id}
                              isFirst={index === firstIndex}
                              isLast={index === firstIndex + lastIndex}
                              resourceVersionId={data.meta.target_revision_id}
                              currentCountryCode={currentCountryCode}
                            />
                          )) ||
                          (data.type.match(CONTENT_COMPONENT_WITH_TITLE) && (
                            <TitleDescription
                              componentID={data.id}
                              firstComponent={index === 0}
                              resourceVersionId={data.meta.target_revision_id}
                              currentCountryCode={currentCountryCode}
                            />
                          )) ||
                          (data.type.match(CONTENT_COMPONENT_FOUR_CARD) && (
                            <CardSliderWithTitle componentID={data.id} resourceVersionId={data.meta.target_revision_id} currentCountryCode={currentCountryCode} />
                          )) ||
                          (data.type.match(THREE_CARD_COMPONENT_WITH_KICKER) && (
                            <ZigzagHexaCards componentID={data.id} resourceVersionId={data.meta.target_revision_id} currentCountryCode={currentCountryCode} />
                          )) ||
                          (data.type.match(THREE_CARD_COMPONENT_WITH_TITLE) && (
                            <ZigzagTriCard componentID={data.id} resourceVersionId={data.meta.target_revision_id} currentCountryCode={currentCountryCode} />
                          )) ||
                          (data.type.match(COMPONENT_HTML_EDITOR) && (
                            <HtmlEditor componentID={data.id} resourceVersionId={data.meta.target_revision_id} currentCountryCode={currentCountryCode} />
                          )) ||
                          (data.type.match(COMPONENT_SPACE) && (
                            <Spacer componentID={data.id} resourceVersionId={data.meta.target_revision_id} currentCountryCode={currentCountryCode} />
                          )) ||
                          (data.type.match(COMPONENT_C15_H1) && (
                            <C15_H1 componentID={data.id} resourceVersionId={data.meta.target_revision_id} currentCountryCode={currentCountryCode} />
                          )) ||
                          (data.type.match(CONTAINER_VIDEO_CARD) && (
                            <C16_VIDEO_CARD componentID={data.id} resourceVersionId={data.meta.target_revision_id} currentCountryCode={currentCountryCode} />
                          )) ||
                          (data.type.match(COMPONENT_C17_VIDEO) && (
                            <C17_VIDEO componentID={data.id} resourceVersionId={data.meta.target_revision_id} currentCountryCode={currentCountryCode} />
                          )) ||
                          (data.type.match(COMPONENT_C18_POINT_LISTS_COMPONENT) && (
                            <C18_POINTLISTS componentID={data.id} resourceVersionId={data.meta.target_revision_id} currentCountryCode={currentCountryCode} />
                          )) ||
                          (data.type.match(COMPONENT_C19_TITLE_LISTS_COMPONENT) && (
                            <C19_TITLELISTS componentID={data.id} resourceVersionId={data.meta.target_revision_id} currentCountryCode={currentCountryCode} />
                          ))||
                          (data.type.match(COMPONENT_C20_FAQ_COMPONENT) && (
                            <C20_FAQ componentID={data.id} resourceVersionId={data.meta.target_revision_id} currentCountryCode={currentCountryCode} />
                          ))


                        }

                      </>
                    );
                  }
                  else {
                    return null;
                  }
                })
                }
                {locator === "/" ? <></> : null}
              </>
            ) : (
              <></>
            )}
          </>
        ))}
    </>
  );
};
export default Page;
